import { getPlatformLink } from '@firi/shared';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import { Meta } from 'components/Meta';
import { Typography } from 'components/Typography';
import { GetStaticProps } from 'next';
import Link from 'next/link';
import Taken from 'shared/svg/taken.svg';

const Page404 = () => (
  <>
    <Meta title={t`404 - Page not found`} noIndex />
    <ContentWrapper constrained className="mt-6">
      <Grid>
        <GridItem className="lg:col-span-4 flex items-center">
          <div>
            <Typography size="lg">
              <Trans>Sorry!</Trans>
            </Typography>
            <Typography as="p">
              <Trans>We can't find the page you are looking for.</Trans>
            </Typography>
            <Typography as="p" className="mt-4">
              <Trans>Here are some other pages you could try instead:</Trans>
            </Typography>
            <ul className="space-y-4 mt-6">
              <li>
                <Typography as="a" href={getPlatformLink({ link: 'vipps-signup' })}>
                  <Trans>Create account with Vipps</Trans>
                </Typography>
              </li>
              <li>
                <Typography as="a" href={getPlatformLink({ link: 'signup' })}>
                  <Trans>Create account with your email</Trans>
                </Typography>
              </li>
              <li>
                <Link href="/" passHref>
                  <Typography as="a">
                    <Trans>Home</Trans>
                  </Typography>
                </Link>
              </li>
              <li>
                <Typography as="a" href={getPlatformLink({ link: 'login' })}>
                  <Trans>Login</Trans>
                </Typography>
              </li>
            </ul>
          </div>
        </GridItem>
        <GridItem className="lg:col-span-8">
          <Taken />
        </GridItem>
      </Grid>
    </ContentWrapper>
  </>
);

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {
      showHeader: false,
      showFooter: false,
    },
  };
};

export default Page404;
